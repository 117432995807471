import {
  ActionList,
  Banner,
  Button,
  ButtonGroup,
  FormLayout,
  HorizontalStack,
  Icon,
  LegacyCard,
  LegacyStack,
  Popover,
  SelectOption,
  Text,
} from '@shopify/polaris'
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons'
import { getIn, useFormikContext } from 'formik'
import { useCallback, useState } from 'react'

import { AnchorField } from '../common/AnchorField'
import { ConfirmDialog, useConfirmDialogState } from '../common/ConfirmDialog'
import {
  gidToId,
  MemoedSelect,
  MemoedTextField,
  mergeArrays,
  pluralize,
  range,
  useFormikHandleChangeCallback,
  useFormikHandleNumberFromStringChangeCallback,
} from '../common/helpers'
import { HelpLabel } from '../common/HelpIcon'
import { MemoedOptionalNumericSelect, OptionalNumericSelectOption } from '../common/NumericSelect'
import { SellingPlanInterval, SellingPlanType } from '../common/plan-models'
import { useShopCurrencySymbol } from '../common/Price'
import {
  PriceAdjustmentTypeField,
  PriceAdjustmentValueField,
} from '../common/PriceAdjustmentFields'
import {
  IntervalCountField,
  IntervalField,
  MaxCyclesField,
  MinCyclesField,
} from '../common/ScheduleFields'
import { useShopShopifySettingsSWR } from '../common/shopify-settings'
import { FinishingBehaviorSelect } from '../common/subscription-fields'
import * as urls from '../common/urls'
import {
  defaultSellingPlanLabel,
  defaultSellingPlanName,
  initialSellingPlanFormData,
  SellingPlanGroupFormData,
  sellingPlanTypeFields,
} from './models-form'
import { PrePaidDeliveriesField } from './PrePaidDeliveriesField'
import { sellingPlanDeleteWarning } from './SellingPlanPage'

const cutoffOptions = mergeArrays(
  [{ label: 'Disabled', value: null } as OptionalNumericSelectOption],
  range(1, 365).map((i) => ({ value: i, label: `${i} ${pluralize(i, 'day')}` }))
)

const preAnchorBehaviorOptions: SelectOption[] = [
  { label: 'On checkout', value: 'ASAP' },
  { label: 'On anchor', value: 'NEXT' },
]

const sellingPlanTypeOptions: { label: string; value: SellingPlanType }[] = [
  { label: 'Basic', value: 'basic' },
  { label: 'Prepaid', value: 'prepaid' },
  { label: 'Advanced', value: 'advanced' },
]

export const VariantCard = ({ index }: { index: number }) => {
  const currencySymbol = useShopCurrencySymbol()
  const confirmDialogState = useConfirmDialogState()

  const { data: shopShopifySettingsData } = useShopShopifySettingsSWR()

  const formik = useFormikContext<SellingPlanGroupFormData>()

  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const handleNumberFromStringChange = useFormikHandleNumberFromStringChangeCallback(
    formik.setFieldValue
  )

  const [addSellingPlanPopoverActive, setAddSellingPlanPopoverActive] = useState(false)

  const toggleAddSellingPlanPopoverActive = useCallback(
    () =>
      setAddSellingPlanPopoverActive((addSellingPlanPopoverActive) => !addSellingPlanPopoverActive),
    []
  )

  const handleRemovingSellingPlan = useCallback(
    (index: number) => {
      const planId = formik.values.sellingPlans[index].id

      const plans = [...(formik.values.sellingPlans || [])]
      plans.splice(index, 1)
      formik.setFieldValue('sellingPlans', plans)

      if (planId) {
        const plansToDelete = [...(formik.values.sellingPlansToDelete || [])]
        plansToDelete.push(planId)
        formik.setFieldValue('sellingPlansToDelete', plansToDelete)
      }
    },
    [formik]
  )

  const handleAddSellingPlan = useCallback(
    (index: number, type: SellingPlanType) => {
      const plans = [...(formik.values.sellingPlans || [])]
      plans.splice(index, 0, initialSellingPlanFormData(type))
      formik.setFieldValue('sellingPlans', plans)
      setAddSellingPlanPopoverActive(false)
    },
    [formik]
  )

  const handleMoveUpSellingPlan = useCallback(() => {
    const plans = [...(formik.values.sellingPlans || [])]
    const deleted = plans.splice(index, 1)
    plans.splice(index - 1, 0, deleted[0])
    formik.setFieldValue('sellingPlans', plans)
  }, [formik, index])

  const handleMoveDownSellingPlan = useCallback(() => {
    const plans = [...(formik.values.sellingPlans || [])]
    const deleted = plans.splice(index, 1)
    plans.splice(index + 1, 0, deleted[0])
    formik.setFieldValue('sellingPlans', plans)
  }, [formik, index])

  const onTypeChange = useCallback(
    (type: SellingPlanType) => {
      formik.setFieldValue(`sellingPlans[${index}].type`, type)

      if (type === 'advanced') {
        return
      }

      if (type === 'prepaid') {
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.minCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.maxCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.anchor`, null)
        return
      }

      if (type === 'basic') {
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.minCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.maxCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].finishingBehavior`, 'immediately')
        formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.anchor`, null)
        formik.setFieldValue(`sellingPlans[${index}].prePaidDeliveries`, 1)
      }
    },
    [formik, index]
  )

  const planGid = formik.values.sellingPlans[index].id
  const planTitle = 'Selling Plan' + (planGid ? ` #${gidToId(planGid)}` : '')
  const type = formik.values.sellingPlans[index].type
  const firstDeliveryTooltip = (
    <>
      <b>On checkout</b> &mdash; schedules the first delivery immediately after checkout. If the
      customer orders within the cutoff window at checkout, the first delivery is scheduled for the
      anchor date, and the second delivery is set for the next anchor date.
      <br />
      <b>On anchor</b> &mdash; schedules the first delivery on the nearest anchor day.
    </>
  )

  const cutoffWindowTooltip = (
    <>
      Skips the first delivery if it's too close to the nearst anchor day.
      <br />
      <br />
      When <b>First delivery</b> is <b>On checkout</b>, it'll prevent two deliveries from being
      scheduled too close to each other.
      <br />
      <br />
      When <b>First delivery</b> is <b>On anchor</b>, it'll jump over to the subsequent anchor day,
      to give you enough time to prepare the order.
    </>
  )

  return (
    <>
      <LegacyCard sectioned>
        <LegacyStack vertical spacing="loose">
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              <HorizontalStack gap="3" blockAlign="center">
                <Text variant="headingMd" as="h2">
                  {planTitle}
                </Text>
                <MemoedSelect
                  name={`sellingPlans[${index}].type`}
                  id={`sellingPlans[${index}].type`}
                  label="Type"
                  labelInline
                  options={sellingPlanTypeOptions}
                  value={formik.values.sellingPlans[index].type}
                  onChange={onTypeChange}
                />
              </HorizontalStack>
            </LegacyStack.Item>
            <LegacyStack alignment="center">
              <ButtonGroup segmented>
                {index !== 0 && (
                  <Button
                    size="slim"
                    icon={<Icon source={ArrowUpMinor} color="base" />}
                    onClick={handleMoveUpSellingPlan}
                  >
                    Up
                  </Button>
                )}
                {index !== formik.values.sellingPlans.length - 1 && (
                  <Button
                    size="slim"
                    icon={<Icon source={ArrowDownMinor} color="base" />}
                    onClick={handleMoveDownSellingPlan}
                  >
                    Down
                  </Button>
                )}
              </ButtonGroup>
              <Button size="slim" onClick={confirmDialogState.show} destructive>
                Remove
              </Button>
            </LegacyStack>
          </LegacyStack>
          <FormLayout>
            {shopShopifySettingsData?.shopUsingThirdPartyFulfillmentServices &&
              formik.values.sellingPlans[index].prePaidDeliveries > 1 && (
                <Banner status="warning">
                  <p>
                    Prepaid orders in Shopify may require you to alter settings in your fulfillment
                    provider. We recommend running tests to ensure scheduled fulfillments can be
                    processed by your provider. Please reach out to your fullfillment provider for
                    more details.
                  </p>
                </Banner>
              )}
            <FormLayout.Group>
              <MemoedTextField
                name={`sellingPlans[${index}].name`}
                id={`sellingPlans[${index}].name`}
                type="text"
                label={
                  <HelpLabel tooltip="Selling plan name displayed in the cart and during checkout. It's recommended that it includes the frequency of deliveries">
                    Full Name
                  </HelpLabel>
                }
                placeholder={defaultSellingPlanName(
                  formik.values.name,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.interval,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.intervalCount
                )}
                value={formik.values.sellingPlans[index].name}
                onChange={handleChange}
                autoComplete="off"
                error={getIn(formik.errors, `sellingPlans[${index}].name`)}
              />
              <MemoedTextField
                name={`sellingPlans[${index}].planSelectorLabel`}
                id={`sellingPlans[${index}].planSelectorLabel`}
                type="text"
                placeholder={defaultSellingPlanLabel(
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.interval,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.intervalCount
                )}
                label={
                  <HelpLabel tooltip="Short label displayed in the plan selector on the product page">
                    Plan selector label
                  </HelpLabel>
                }
                value={formik.values.sellingPlans[index].planSelectorLabel}
                onChange={handleChange}
                autoComplete="off"
                error={getIn(formik.errors, `sellingPlans[${index}].planSelectorLabel`)}
              />
            </FormLayout.Group>
            <LegacyStack distribution="fillEvenly" spacing="loose">
              <LegacyStack distribution="fillEvenly">
                <IntervalCountField
                  name={`sellingPlans[${index}].deliveryPolicy.recurring.intervalCount`}
                  id={`sellingPlans[${index}].deliveryPolicy.recurring.intervalCount`}
                  label={
                    <HelpLabel tooltip="Deliver every is the frequency of deliveries.">
                      Deliver Every
                    </HelpLabel>
                  }
                  onChange={handleChange}
                  value={formik.values.sellingPlans[index].deliveryPolicy.recurring.intervalCount}
                />
                <IntervalField
                  name={`sellingPlans[${index}].deliveryPolicy.recurring.interval`}
                  id={`sellingPlans[${index}].deliveryPolicy.recurring.interval`}
                  label="  "
                  onChange={(val: SellingPlanInterval, id: string) => {
                    handleChange(val, id)
                    handleChange(null, `sellingPlans[${index}].deliveryPolicy.recurring.anchor`)
                  }}
                  value={formik.values.sellingPlans[index].deliveryPolicy.recurring.interval}
                />
              </LegacyStack>
              {(sellingPlanTypeFields(type).prepaid ||
                sellingPlanTypeFields(type).finishingBehavior) && (
                <LegacyStack distribution="fillEvenly">
                  {sellingPlanTypeFields(type).prepaid && (
                    <PrePaidDeliveriesField
                      value={formik.values.sellingPlans[index].prePaidDeliveries}
                      onChange={(v) =>
                        formik.setFieldValue(`sellingPlans[${index}].prePaidDeliveries`, v)
                      }
                      pagePath={urls.planUrl(formik.values.id)}
                    />
                  )}
                  {sellingPlanTypeFields(type).finishingBehavior && (
                    <FinishingBehaviorSelect
                      name={`sellingPlans[${index}].finishingBehavior`}
                      id={`sellingPlans[${index}].finishingBehavior`}
                      onChange={handleChange as (val: string, id: string) => any}
                      value={formik.values.sellingPlans[index].finishingBehavior}
                    />
                  )}
                </LegacyStack>
              )}
            </LegacyStack>
            {sellingPlanTypeFields(type).anchors && (
              <FormLayout.Group>
                <LegacyStack distribution="fillEvenly">
                  <AnchorField
                    id={`sellingPlans[${index}].deliveryPolicy.recurring.anchor`}
                    interval={formik.values.sellingPlans[index].deliveryPolicy.recurring.interval}
                    anchor={formik.values.sellingPlans[index].deliveryPolicy.recurring.anchor}
                    onChange={handleChange}
                    pagePath={urls.planUrl(formik.values.id)}
                  />
                  <LegacyStack distribution="fillEvenly">
                    <MemoedSelect
                      name={`sellingPlans[${index}].deliveryPolicy.recurring.preAnchorBehavior`}
                      id={`sellingPlans[${index}].deliveryPolicy.recurring.preAnchorBehavior`}
                      label={<HelpLabel tooltip={firstDeliveryTooltip}>First Delivery</HelpLabel>}
                      options={preAnchorBehaviorOptions}
                      onChange={handleChange}
                      value={
                        formik.values.sellingPlans[index].deliveryPolicy.recurring.preAnchorBehavior
                      }
                      disabled={!formik.values.sellingPlans[index].deliveryPolicy.recurring.anchor}
                    />
                    <MemoedOptionalNumericSelect
                      name={`sellingPlans[${index}].deliveryPolicy.recurring.cutoff`}
                      id={`sellingPlans[${index}].deliveryPolicy.recurring.cutoff`}
                      label={<HelpLabel tooltip={cutoffWindowTooltip}>Cutoff window</HelpLabel>}
                      options={cutoffOptions}
                      onChange={handleNumberFromStringChange}
                      value={formik.values.sellingPlans[index].deliveryPolicy.recurring.cutoff}
                      disabled={!formik.values.sellingPlans[index].deliveryPolicy.recurring.anchor}
                    />
                  </LegacyStack>
                </LegacyStack>
              </FormLayout.Group>
            )}
            <FormLayout.Group>
              <PriceAdjustmentTypeField
                name={`sellingPlans[${index}].fixedPricingPolicy.adjustmentType`}
                id={`sellingPlans[${index}].fixedPricingPolicy.adjustmentType`}
                onChange={handleChange}
                value={formik.values.sellingPlans[index].fixedPricingPolicy.adjustmentType}
              />
              <PriceAdjustmentValueField
                name={`sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`}
                id={`sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`}
                prePaidDeliveries={formik.values.sellingPlans[index].prePaidDeliveries}
                currencySymbol={currencySymbol}
                adjustmentType={formik.values.sellingPlans[index].fixedPricingPolicy.adjustmentType}
                value={formik.values.sellingPlans[index].fixedPricingPolicy.adjustmentValue}
                error={getIn(
                  formik.errors,
                  `sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`
                )}
                onChange={handleChange}
              />
            </FormLayout.Group>
            {sellingPlanTypeFields(type).minMaxCycles && (
              <FormLayout.Group>
                <MinCyclesField
                  name={`sellingPlans[${index}].billingPolicy.recurring.minCycles`}
                  id={`sellingPlans[${index}].billingPolicy.recurring.minCycles`}
                  onChange={handleNumberFromStringChange}
                  value={formik.values.sellingPlans[index].billingPolicy.recurring.minCycles}
                />
                <MaxCyclesField
                  name={`sellingPlans[${index}].billingPolicy.recurring.maxCycles`}
                  id={`sellingPlans[${index}].billingPolicy.recurring.maxCycles`}
                  onChange={handleNumberFromStringChange}
                  value={formik.values.sellingPlans[index].billingPolicy.recurring.maxCycles}
                />
              </FormLayout.Group>
            )}
            <MemoedTextField
              name={`sellingPlans[${index}].description`}
              id={`sellingPlans[${index}].description`}
              type="text"
              label={
                <HelpLabel tooltip="Detailed commitment description displayed on the product page when the plan is selected (optional)">
                  Description
                </HelpLabel>
              }
              placeholder=""
              multiline={3}
              value={formik.values.sellingPlans[index].description}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormLayout>
        </LegacyStack>
      </LegacyCard>

      <HorizontalStack align="start">
        <Popover
          active={addSellingPlanPopoverActive}
          activator={
            <div style={{ margin: '12px' }}>
              <Button
                plain
                disclosure={addSellingPlanPopoverActive ? 'up' : 'down'}
                onClick={toggleAddSellingPlanPopoverActive}
              >
                {index === formik.values.sellingPlans.length - 1
                  ? 'Add selling plan'
                  : 'Insert selling plan'}
              </Button>
            </div>
          }
          onClose={toggleAddSellingPlanPopoverActive}
        >
          <ActionList
            actionRole="menuitem"
            items={[
              { content: 'Basic', onAction: () => handleAddSellingPlan(index + 1, 'basic') },
              { content: 'Prepaid', onAction: () => handleAddSellingPlan(index + 1, 'prepaid') },
              { content: 'Advanced', onAction: () => handleAddSellingPlan(index + 1, 'advanced') },
            ]}
          />
        </Popover>
      </HorizontalStack>

      <ConfirmDialog
        state={confirmDialogState}
        destructive
        onConfirm={() => handleRemovingSellingPlan(index)}
        message={sellingPlanDeleteWarning}
        confirmButtonTitle={'Remove'}
      />
    </>
  )
}
