import { SelectOption, SelectProps } from '@shopify/polaris'
import React from 'react'

import { HelpLabel } from '../common/HelpIcon'
import { MemoedSelect } from './helpers'
import { FinishingBehavior, finishingBehaviorLabels } from './subscription-models'

const finishingBehaviorOptions: SelectOption[] = [
  { value: 'immediately', label: finishingBehaviorLabels['immediately'] },
  { value: 'end-of-cycle', label: finishingBehaviorLabels['end-of-cycle'] },
]

interface FinishingBehaviorSelectProps extends Omit<SelectProps, 'label' | 'onChange' | 'options'> {
  onChange: (val: FinishingBehavior, id: string) => any
}

export const FinishingBehaviorSelect = (props: FinishingBehaviorSelectProps) => (
  <MemoedSelect
    {...props}
    label={
      <HelpLabel tooltip="Finish subscriptions either immediately on the last payment / cancellation request, or at the end of the billing cycle.">
        Finishing Behavior
      </HelpLabel>
    }
    options={finishingBehaviorOptions}
    onChange={props.onChange as (val: string) => any}
  />
)
