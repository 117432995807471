import { setIn } from 'formik'

import { Activity } from '../common/activity-models'
import { SellingPlanAnchor, SellingPlanPreAnchorBehavior } from '../common/anchor-models'
import { countPrefix, pluralize } from '../common/helpers'
import {
  SellingPlanInterval,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanType,
} from '../common/plan-models'
import { getPriceAdjustmentValueError } from '../common/PriceAdjustmentFields'
import {
  SellingPlan,
  SellingPlanFinishingBehavior,
  SellingPlanGroup,
  SellingPlanInventoryPolicyReserve,
  SellingPlanPWData,
} from './models-common'

export interface SellingPlanRecurringBillingPolicyFormData {
  createdAt?: string
  interval: SellingPlanInterval
  intervalCount: number
  maxCycles: number | null
  minCycles: number | null
}

export interface SellingPlanBillingPolicyFormData {
  recurring: SellingPlanRecurringBillingPolicyFormData
}

export interface SellingPlanRecurringDeliveryPolicyFormData {
  anchor: SellingPlanAnchor | null
  createdAt?: string
  cutoff: number | null
  interval: SellingPlanInterval
  intervalCount: number
  preAnchorBehavior: SellingPlanPreAnchorBehavior
}

export interface SellingPlanDeliveryPolicyFormData {
  recurring: SellingPlanRecurringDeliveryPolicyFormData
}

export interface SellingPlanPricingPolicyValueFormData {
  fixedValue?: number
  percentage?: number
}

export interface SellingPlanInventoryPolicyFormData {
  reserve: SellingPlanInventoryPolicyReserve
}

export interface SellingPlanFixedPricingPolicyFormData {
  adjustmentType: SellingPlanPricingPolicyAdjustmentType
  adjustmentValue: string
}

export interface SellingPlanRecurringPricingPolicyFormData {
  adjustmentType: SellingPlanPricingPolicyAdjustmentType
  adjustmentValue: SellingPlanPricingPolicyValueFormData
  afterCycle?: number
  createdAt?: string
}

export interface SellingPlanFormData {
  billingPolicy: SellingPlanBillingPolicyFormData
  createdAt?: string
  deliveryPolicy: SellingPlanDeliveryPolicyFormData
  inventoryPolicy: SellingPlanInventoryPolicyFormData
  prePaidDeliveries: number
  description?: string
  id?: string
  name: string
  category: string
  planSelectorLabel: string
  position?: number
  fixedPricingPolicy: SellingPlanFixedPricingPolicyFormData
  finishingBehavior: SellingPlanFinishingBehavior
  type: SellingPlanType
  tags: string
}

export interface SellingPlanGroupFormData {
  createdAt?: string
  description?: string
  id?: string
  merchantCode: string
  name: string
  planSelectorTitle: string
  position?: number
  summary: string
  productCount?: number
  sellingPlans: SellingPlanFormData[]
  sellingPlansToDelete?: string[]
  activities: Activity[]
}

// #endregion

const parseBillingPolicyResponse = (plan: SellingPlan): SellingPlanBillingPolicyFormData => {
  const policy = plan.billingPolicy

  if (policy.__typename !== 'SellingPlanRecurringBillingPolicy') {
    throw new Error('Unknown billing policy returned by Shopify')
  }

  return {
    recurring: policy,
  }
}

const parseDeliveryPolicyResponse = (plan: SellingPlan): SellingPlanDeliveryPolicyFormData => {
  const policy = plan.deliveryPolicy

  if (policy.__typename !== 'SellingPlanRecurringDeliveryPolicy') {
    throw new Error('Unknown delivery policy returned by Shopify')
  }

  const ret = {
    ...policy,
    anchor: policy.anchors?.[0] ?? null,
  }

  return {
    recurring: ret,
  }
}

const parseFixedPricingPolicyResponse = (
  plan: SellingPlan
): SellingPlanFixedPricingPolicyFormData => {
  const policies = plan.pricingPolicies.filter(
    (p) => p.__typename === 'SellingPlanFixedPricingPolicy'
  )
  const policy = policies.length > 0 ? policies[0] : null
  let type = policy?.adjustmentType ?? SellingPlanPricingPolicyAdjustmentType.NONE
  let value: string = '0'

  switch (type) {
    case SellingPlanPricingPolicyAdjustmentType.PERCENTAGE:
      value = `${policy?.adjustmentValue.percentage ?? 0}`
      break
    case SellingPlanPricingPolicyAdjustmentType.FIXED_AMOUNT:
    case SellingPlanPricingPolicyAdjustmentType.PRICE:
    case SellingPlanPricingPolicyAdjustmentType.NONE:
      value = policy?.adjustmentValue.amount ?? '0'
      break
  }

  if (type === SellingPlanPricingPolicyAdjustmentType.FIXED_AMOUNT && parseFloat(value) === 0) {
    type = SellingPlanPricingPolicyAdjustmentType.NONE
  }

  return {
    adjustmentType: type,
    adjustmentValue: value,
  }
}

const parseInventoryPolicyResponse = (plan: SellingPlan): SellingPlanInventoryPolicyFormData =>
  plan.inventoryPolicy ?? {
    reserve: defaultSellingPlanInventoryPolicyReserve,
  }

const parseSellingPlanResponse = (data: SellingPlan): SellingPlanFormData => {
  const billingPolicy = parseBillingPolicyResponse(data)
  const deliveryPolicy = parseDeliveryPolicyResponse(data)
  const inventoryPolicy = parseInventoryPolicyResponse(data)
  const prePaidDeliveries = Math.round(
    billingPolicy.recurring.intervalCount / deliveryPolicy.recurring.intervalCount
  )
  const sellingPlanType = (): SellingPlanType => {
    let sellingPlanType: SellingPlanType = 'basic'

    if (prePaidDeliveries > 1) {
      sellingPlanType = 'prepaid'
    }

    if (
      deliveryPolicy.recurring.anchor !== null ||
      billingPolicy.recurring.minCycles !== null ||
      billingPolicy.recurring.maxCycles !== null ||
      data.finishingBehavior === 'end-of-cycle'
    ) {
      sellingPlanType = 'advanced'
    }

    return sellingPlanType
  }

  return {
    billingPolicy,
    createdAt: data.createdAt,
    deliveryPolicy,
    inventoryPolicy,
    prePaidDeliveries,
    description: data.description,
    id: data.id,
    name: data.name,
    category: data.category,
    planSelectorLabel: data.options[0],
    position: data.position,
    fixedPricingPolicy: parseFixedPricingPolicyResponse(data),
    finishingBehavior: data.finishingBehavior ?? 'immediately',
    type: sellingPlanType(),
    tags: (data.tags ?? []).join(', '),
  }
}

interface SellingPlanTypeFields {
  prepaid: boolean
  minMaxCycles: boolean
  anchors: boolean
  finishingBehavior: boolean
}

export const sellingPlanTypeFields = (type: SellingPlanType): SellingPlanTypeFields => {
  return {
    prepaid: type === 'prepaid' || type === 'advanced',
    minMaxCycles: type === 'advanced',
    anchors: type === 'advanced',
    finishingBehavior: type === 'prepaid' || type === 'advanced',
  }
}

const mergeSellingPlanData = (spg: SellingPlanGroup): SellingPlan[] => {
  const gqlPlans = spg.gql_data.sellingPlans
  const pwPlans = spg.pw_data?.sellingPlans ?? []
  const pwPlansByName: Record<string, SellingPlanPWData> = {}
  const plans: SellingPlan[] = []

  for (const pwPlan of pwPlans) {
    pwPlansByName[pwPlan.name] = pwPlan
  }

  for (const gqlPlan of gqlPlans) {
    const pwPlan = pwPlansByName[gqlPlan.name] ?? null
    plans.push({ ...gqlPlan, ...pwPlan })
  }

  return plans
}

export const parseSellingPlanGroupResponse = (spg: SellingPlanGroup): SellingPlanGroupFormData => {
  const data = spg.gql_data
  const sellingPlans = mergeSellingPlanData(spg)
  const parsedSellingPlans = sellingPlans
    .map(parseSellingPlanResponse)
    .sort((sp1, sp2) => ((sp1.position ?? 0) > (sp2.position ?? 0) ? 1 : -1))

  return {
    createdAt: data.createdAt,
    description: data.description,
    id: data.id,
    merchantCode: data.merchantCode,
    name: data.name,
    planSelectorTitle: data.options[0],
    summary: data.summary || '',
    productCount: data.productCount,
    sellingPlansToDelete: [],
    sellingPlans: parsedSellingPlans,
    activities: spg.activities,
  }
}

export const initialSellingPlanFormData = (type: SellingPlanType): SellingPlanFormData => ({
  name: '',
  description: '',
  planSelectorLabel: '',
  category: 'SUBSCRIPTION',
  billingPolicy: {
    recurring: {
      interval: SellingPlanInterval.WEEK,
      intervalCount: 1,
      minCycles: null,
      maxCycles: null,
    },
  },
  deliveryPolicy: {
    recurring: {
      anchor: null,
      interval: SellingPlanInterval.WEEK,
      intervalCount: 1,
      cutoff: null,
      preAnchorBehavior: 'NEXT',
    },
  },
  inventoryPolicy: {
    reserve: defaultSellingPlanInventoryPolicyReserve,
  },
  prePaidDeliveries: 1,
  fixedPricingPolicy: {
    adjustmentType: SellingPlanPricingPolicyAdjustmentType.NONE,
    adjustmentValue: '0',
  },
  finishingBehavior: type === 'prepaid' ? 'end-of-cycle' : 'immediately',
  type,
  tags: '',
})

export const initialSellingPlanGroupFormData = (): SellingPlanGroupFormData => ({
  description: '',
  merchantCode: '',
  name: '',
  planSelectorTitle: '',
  summary: '',
  sellingPlans: [initialSellingPlanFormData('basic')],
  sellingPlansToDelete: [],
  activities: [],
})

export const defaultSellingPlanGroupName = 'Subscribe and Save'
export const defaultSellingPlanGroupMerchantCode = defaultSellingPlanGroupName
export const defaultSellingPlanGroupOption = 'Deliver every'
export const defaultSellingPlanInventoryPolicyReserve: SellingPlanInventoryPolicyReserve = 'ON_SALE'

export const defaultSellingPlanName = (
  groupName: string,
  interval: string,
  intervalCount: number
) =>
  `${groupName || defaultSellingPlanGroupName} - delivered every ${countPrefix(
    intervalCount,
    false
  )}${pluralize(intervalCount, interval.toLocaleLowerCase())}`

export const defaultSellingPlanLabel = (interval: string, intervalCount: number): string =>
  `${countPrefix(intervalCount, true)}${pluralize(intervalCount, interval.toLowerCase())}`

export const sellingPlanGroupFormDataWithDefaultValues = (
  spg: SellingPlanGroupFormData
): SellingPlanGroupFormData => {
  spg = {
    ...spg,
    name: spg.name.trim() || defaultSellingPlanGroupName,
    merchantCode: spg.merchantCode.trim() || defaultSellingPlanGroupMerchantCode,
    planSelectorTitle: spg.planSelectorTitle.trim() || defaultSellingPlanGroupOption,
  }

  spg.sellingPlans = spg.sellingPlans.map((plan) => {
    const { interval, intervalCount } = plan.deliveryPolicy.recurring

    return {
      ...plan,
      name: plan.name.trim() || defaultSellingPlanName(spg.name, interval, intervalCount),
      planSelectorLabel:
        plan.planSelectorLabel.trim() || defaultSellingPlanLabel(interval, intervalCount),
    }
  })

  return spg
}

export const validateSellingPlanGroupFormData = (values: SellingPlanGroupFormData) => {
  const fixedValues = sellingPlanGroupFormDataWithDefaultValues(values)
  let errors = {}

  fixedValues.sellingPlans.forEach((sp: SellingPlanFormData, index: number) => {
    const message = getPriceAdjustmentValueError(
      sp.fixedPricingPolicy.adjustmentType,
      sp.fixedPricingPolicy.adjustmentValue
    )

    if (message) {
      errors = setIn(errors, `sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`, message)
    }
  })

  const sellingPlanNames = fixedValues.sellingPlans.map((sp) => sp.name)
  sellingPlanNames.forEach((name: string, index: number) => {
    if (sellingPlanNames.indexOf(name) < index) {
      errors = setIn(
        errors,
        `sellingPlans[${index}].name`,
        'Plan selector full names must be unique'
      )
    }
  })

  const sellingPlanLabels = fixedValues.sellingPlans.map((sp) => sp.planSelectorLabel)
  sellingPlanLabels.forEach((name: string, index: number) => {
    if (sellingPlanLabels.indexOf(name) < index) {
      errors = setIn(
        errors,
        `sellingPlans[${index}].planSelectorLabel`,
        'Plan selector labels must be unique'
      )
    }
  })

  return errors
}
