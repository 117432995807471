import { LegacyCard, LegacyStack } from '@shopify/polaris'
import React, { useState } from 'react'

import { anchorMonthNames, anchorWeekDayNames, SellingPlanAnchor } from '../common/anchor-models'
import { formatOrdinal, pluralize } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'
import {
  defaultFinishingBehavior,
  finishingBehaviorLabels,
  SubscriptionContract,
} from '../common/subscription-models'
import { DetailsItem } from './DetailsItem'
import { ScheduleEditDialog } from './ScheduleEditDialog'

const anchorLabel = (anchor: SellingPlanAnchor) => {
  if (anchor.type === 'WEEKDAY') {
    return anchorWeekDayNames[anchor.day]
  }

  if (anchor.type === 'MONTHDAY') {
    return `${formatOrdinal(anchor.day)} of the month`
  }

  return `${anchorMonthNames[anchor.month ?? 0]} ${formatOrdinal(anchor.day)}`
}

export const ScheduleCard = ({ sc }: { sc: SubscriptionContract }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const scheduleTitle = (
    <HelpTitle title="Schedule">
      Current delivery schedule details including frequency and length of subscription term payments
      if applicable.
    </HelpTitle>
  )

  const actions = sc.is_editable
    ? [
        {
          content: 'Edit',
          onAction: () => setEditDialogOpen(true),
        },
      ]
    : []

  const anchor = sc.gql_data.deliveryPolicy.anchors?.[0] ?? null

  return (
    <LegacyCard title={scheduleTitle} sectioned actions={actions}>
      <LegacyStack vertical spacing="extraTight">
        <DetailsItem
          label="Deliver every"
          helpIcon=" Deliver every is the frequency of deliveries."
        >
          {sc.gql_data.deliveryPolicy.intervalCount}{' '}
          {pluralize(
            sc.gql_data.deliveryPolicy.intervalCount,
            sc.gql_data.deliveryPolicy.interval.toLowerCase()
          )}
        </DetailsItem>

        <DetailsItem
          label="Bill every"
          helpIcon="Bill every is the frequency of billing charges in relation to the frequency of deliveries."
        >
          {sc.pre_paid_deliveries} {pluralize(sc.pre_paid_deliveries, 'delivery', 'deliveries')}
        </DetailsItem>

        {anchor && <DetailsItem label="Anchor day">{anchorLabel(anchor)}</DetailsItem>}

        <DetailsItem
          label="Min payments"
          helpIcon="The minimum number of payments required before the customer has the option to cancel if allowed in the app settings."
        >
          {sc.gql_data.billingPolicy.minCycles || 'not set'}
        </DetailsItem>
        <DetailsItem
          label="Max payments"
          helpIcon="The maximum number of payments before the subscription expires due to completion."
        >
          {sc.gql_data.billingPolicy.maxCycles || 'not set'}
        </DetailsItem>
        <DetailsItem
          label="Finishing behavior"
          helpIcon="Finish subscriptions either immediately on the last payment / cancellation request, or at the end of the billing cycle."
        >
          {finishingBehaviorLabels[sc.pw_data.finishingBehavior ?? defaultFinishingBehavior]}
        </DetailsItem>
      </LegacyStack>
      <ScheduleEditDialog sc={sc} open={editDialogOpen} close={() => setEditDialogOpen(false)} />
    </LegacyCard>
  )
}
