import { Button, TextField } from '@shopify/polaris'
import { QuestionMarkMajor } from '@shopify/polaris-icons'
import React from 'react'

import { pluralize, useDialogActivator, useSystemData } from '../common/helpers'
import { HelpLabel } from '../common/HelpIcon'
import { NumericSelect } from '../common/NumericSelect'
import { PrepaidScopesNeededModal } from '../common/PrepaidScopeNeededModal'

const options = Array.from({ length: 52 }, (x, i) => ({
  label: `${i + 1} ${i === 0 ? 'delivery' : 'deliveries'}`,
  value: i + 1,
}))

interface MockFieldProps {
  value: string
  pagePath: string
}

const MockField = (props: MockFieldProps) => {
  const dialogActivator = useDialogActivator()

  return (
    <>
      <TextField
        label="Bill every"
        value={props.value}
        onChange={() => null}
        disabled={true}
        connectedRight={<Button icon={QuestionMarkMajor} onClick={dialogActivator.show} />}
        autoComplete="off"
      />
      <PrepaidScopesNeededModal activator={dialogActivator} pagePath={props.pagePath}>
        <p>
          To start using pre-paid deliveries, you need to accept additional permissions in Shopify
        </p>
      </PrepaidScopesNeededModal>
    </>
  )
}

interface PrePaidDeliveriesFieldProps {
  value: number
  onChange?: (value: number) => any
  pagePath: string
}

export const PrePaidDeliveriesField = (props: PrePaidDeliveriesFieldProps) => {
  const systemData = useSystemData()

  if (!systemData || !systemData.flags.has_fulfillment_order_scopes) {
    return (
      <MockField
        value={`${props.value} ${pluralize(props.value, 'delivery', 'deliveries')}`}
        pagePath={props.pagePath}
      />
    )
  }

  return (
    <NumericSelect
      label={
        <HelpLabel tooltip="This setting defines the number of deliveries included in each subscription order and determines the billing frequency.">
          Prepaid / Bill Every
        </HelpLabel>
      }
      value={props.value}
      options={options}
      onChange={props.onChange}
    />
  )
}
